<template>
  <div class="checkbox">
    <div v-if="produto">
      <input
        class="input"
        :checked="produto.Selecionado"
        type="checkbox"
        @change="changeValue"
      />
    </div>
    <input v-else class="input" type="checkbox" @change="changeValue" />
  </div>
</template>

<script>
export default {
  name: "checkCustom",
  props: {
    checked_: Boolean,
    produto: Object,
  },
  data() {
    return {
      select: false,
      prod: false,
    };
  },
  methods: {
    changeValue() {
      if (this.produto) {
        this.produto.Selecionado = !this.produto.Selecionado;
        this.$emit("selected", this.produto.Selecionado);
      } else {
        this.select = !this.select;
        this.$emit("selected", this.select);
      }
    },
  },
  mounted() {
    if (this.checked_ && !this.produto) {
      this.select = true;
    }
    if (this.produto) {
      this.prod = true;
    }
  },
};
</script>

<style>
.checkbox {
  /* padding: 5px 0; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  /* background:rgba(255, 255, 0, 0.392); */
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  /* color: #fff; */
  width: 18px;
  height: 18px;
  border: 0.15em solid #1da0f2;
  border-radius: 5px;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input:checked {
  background-color: #1da0f2;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.imagem-selec {
  height: 20px !important;
}
</style>
